/*
$siteCol-f-wht: #fff;
$siteCol-k-blk: #000;
*/
/* Document
   ========================================================================== */
@-ms-viewport {
	width: device-width;
	initial-scale: 1;
}

html {
	font-family: "Font Awesome 5 Free", "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
	font-size: 0.625rem;
	font-weight: 500;
	line-height: 1;
	text-justify: inter-ideograph;
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
}

/* Sections
   ========================================================================== */
body {
	margin: 0;
}

*, *::before, *::after {
	box-sizing: border-box;
}

main {
	display: block;
}

h1, h2, h3,
h4, h5, h6 {
	margin: 0;
	font-size: inherit;
	font-weight: 700;
	line-height: 1.4;
}

/* Grouping content
   ========================================================================== */
address {
	font-style: normal;
}

figure {
	margin: 0;
}

hr {
	height: 1px;
	margin: 0;
	padding: 0;
	border: 0;
	overflow: hidden;
}

pre {
	font-family: monospace, monospace;
	font-size: inherit;
}

/* Links
   ========================================================================== */
a {
	color: currentColor;
	text-decoration: none;
	outline: 0;
	background-color: transparent;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/* Text-level semantics
   ========================================================================== */
p {
	margin: 0;
}

abbr[title] {
	border-bottom: 0;
	text-decoration: underline dotted;
}

b, strong {
	font-weight: 700;
}

em {
	font-weight: 700;
	font-style: normal;
}

code, kbd, samp {
	font-family: monospace, monospace;
	font-size: inherit;
}

small {
	font-size: 80%;
}

sup, sub {
	font-size: 75%;
	line-height: 0;
	vertical-align: baseline;
	position: relative;
}

sup {
	top: -0.5em;
}

sub {
	bottom: -0.25em;
}

/* Lists
   ========================================================================== */
dl, dt, dd,
ol, ul, li {
	margin: 0;
	padding: 0;
	list-style: none;
	list-style-image: none;
}

dt {
	font-weight: 700;
}

/* Tables
   ========================================================================== */
table {
	width: 100%;
	border-collapse: collapse;
	border-spacing: 0;
	table-layout: fixed;
}

td, th {
	padding: 0;
}

/* Embedded content
   ========================================================================== */
img, svg,
audio, video,
canvas, iframe {
	vertical-align: middle;
}

img {
	max-width: 100%;
	border: 0;
}

img[src$=".svg"] {
	height: 100%;
}

svg:not(:root) {
	overflow: hidden;
}

/* Forms
   ========================================================================== */
button,
input,
optgroup,
select,
textarea {
	margin: 0;
	color: currentColor;
	font-family: inherit;
	font-size: inherit;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

button,
input {
	overflow: visible;
}

button,
select {
	text-transform: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
	-webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
	padding: 0;
	border: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
	outline: dotted 1px ButtonText;
}

fieldset {
	margin: 0;
	padding: 0;
	border: 0;
}

legend {
	max-width: 100%;
	padding: 0;
	color: inherit;
	white-space: normal;
	box-sizing: border-box;
	display: table;
}

progress {
	vertical-align: baseline;
}

textarea {
	overflow: auto;
	resize: vertical;
}

[type="checkbox"],
[type="radio"] {
	padding: 0;
	box-sizing: border-box;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
	height: auto;
}

[type="search"] {
	outline-offset: -2px;
	-webkit-appearance: textfield;
}

[type="search"]::-webkit-search-decoration {
	-webkit-appearance: none;
}

::-webkit-file-upload-button {
	font: inherit;
	-webkit-appearance: button;
}

/* Interactive
   ========================================================================== */
details {
	display: block;
}

summary {
	display: list-item;
}

/* Misc
   ========================================================================== */
template,
[hidden] {
	display: none;
}
